import Vue from 'vue'
import Router from "vue-router";
// import VueMeta from 'vue-meta';
import store from './store';

Vue.use(Router)
// Vue.use(VueMeta)



const router = new Router({
  mode: "history",
  scrollBehavior (to, from, savedPosition) {
    to
    from 
    savedPosition
    return new Promise((resolve, reject) => {
      reject
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 300)
    })
  },
  
    routes: [
    
      {
        path: '/auth',
        // name:'auth',
        component: () => import('./views/pages/Index.vue'),
        children: [{
          name: 'Login',
          path: '',
          component: () => import('./views/pages/Login.vue')
        }]
      },
      {
        path: '/',
        component: () => import('./views/dashboard/Index.vue'),
        beforeEnter: (to, from, next) => {
          
          if (localStorage.getItem('tokinn') && store.state.AdminInfo.authe==true) {
            next()
          } else {
            // next({
            //   name: 'Login'
            // });
            router.push({ path: '/auth' });
          }
        },   
        children: [
          {
            name: 'الاحصاءات',
            path: 'stats',
            component: () => import('./views/dashboard/chart.vue')
          },

     
          {
            path:'applicant',
            name:'المتقدمين على الوظيفة',
            component: () => import('./views/dashboard/applicant.vue'),
          }


       
        ]   
        
      },
    
    
    
       
       
    ]
    });
    
    
    
    
    router.beforeEach((to, from, next) => {
     
        window.document.title = to.name ;
   
      next();
    });
    
    export default router;

